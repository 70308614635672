@import "../../global-variable";

.admin-page-header {
  background: linear-gradient(0deg, rgba(3, 122, 255, 0.04), rgba(3, 122, 255, 0.04)), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 200px;
  border-bottom: 1px solid $grey-more-middle;

  .page-name {
    color: $white;
    font: bold 56px/64px Roboto;
    padding: 32px 0 0 48px;
  }
}
