@import "../../global-variable";

.button-donate-container {

  .btn-donate {
    //@include btn-cancel;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    background: #0B2771;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.06);
    border: none;
    border-radius: 4px;
    width: 100%;
    min-height: 32px;
  }
}