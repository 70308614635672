@import '../../global-variable';

@import "sortContainer";
@import "tagline";
@import "infoBlock";

.main-page-container {
  height: 100%;
  width: 100%;

  .bg-photo-main-page {
    background: {
      image: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 45%), url(../../../resources/images/fon-main.png);
      position: 30% 80%;
      size: cover;
    }
    height: 600px;
    width: 100%;

    @media #{$small-down} {
      background: {
        image: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 45%), url(../../../resources/images/fon-main-small.png);
        position: 30% 80%;
        size: cover;
      }
    }

    .tagline-container {
      width: 100%;
      align-items: center;
      height: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $white;
      padding-top: 15px;

      @media #{$large-up} {
        width: $global_width;
        align-items: flex-end;
      }
    }
  }

  .image-main-page {
    background: {
      image: url(../../../resources/images/flowers-main.jpg);
      position: 0% 80%;
      size: cover;
    }
    min-height: 144px;
    width: 100%;
  }
}
