@import "../../global-variable";

.links-block {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .link-horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;

    .link {
      @include text-m;
      display: flex;
      flex-direction: row;
      a{
        color: $white;
        margin-left: 3px;
        white-space: nowrap;

        &:hover {
          text-decoration: none;
        }
      }
    }

    span {
      font-weight: bold;
      margin: 0;
      padding-left: 3px;
      @media #{$medium-down} {
        display: none;
      }
    }

    @media #{$medium-down} {
      flex-direction: column;
    }

  }

  .link-vertical {
    align-items: center;
    display: flex;
    flex-direction: column;

    .link {
      @include text-xs;
      a{
        color: $white;
        &:hover {
          text-decoration: none;
        }
      }

    }
  }
  @media #{$medium-down} {
    text-align: center;
  }
}