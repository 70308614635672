@import "../../global-variable";

.registration-form {
  background-color: $grey;
  border-radius: 15px;
  padding: 20px;
  width: 700px;

  .registration {
    display: flex;
    flex-direction: column;
    color: $black;
    margin-top: 23px;

    .registration-field {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;

      label {
        font-size: 14px;
        line-height: 18px;
      }

      .is-required:after {
        content: '*';
        color: #BE1E24;
        padding-left: 3px;
      }

      input, textarea {
        font-size: 12px;
        padding: 5px;
        color: #555;
        border-radius: 4px;
        border: 1px solid #ccc;
        transition: border-color .3s;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      }
    }

    .condition-robot {
      font-size: 12px;
      line-height: 12px;
    }
  }


  .send-request {
    color: #fff;
    background-color: #BE1E24;
    border: none;
    font-size: 16px;
    line-height: 32px;
    border-radius: 4px;
    margin: 10px 0;
    width: 100%;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.58);
    }
  }

  .send-request[disabled] {
    background-color: #cccccc;
    border: 2px solid #7F7F7F;

    &:hover {
      background-color: #cccccc;
      cursor: default;
      box-shadow: none;
    }
  }

  .required-fields-message {
    color: $black;
    font-size: 12px;
    line-height: 12px;

    &:before {
      content: '(*)';
      color: #BE1E24;
      padding: 0 3px;
    }
  }
}