@import "../../global-variable";

.about-fund-container-admin {
  height: 100%;
  padding: 10px 25px;
  @media #{$medium-down} {
    padding: 0;
  }

  .about-fund {
    @include remove-style-link;
    border-radius: $br-size-4;

    &:hover {
      cursor: pointer;
      text-decoration: none;
      box-shadow: 0 0 5px 2px rgba(0,0,0,0.4);
    }
  }

  .block-about {
    border: 1px solid $grey-middle;
    box-sizing: border-box;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.1);
    border-radius: $br-size-4;
    background-color: $white;
    padding: 0 10px;
    margin-top: 10px;
  }

  .block-about-link {
    @include remove-style-link;

    .title-block-about {
      @include text-l;
      padding: 10px;
    }

    &:hover {
      cursor: pointer;
      text-decoration: none;
      box-shadow: 0 0 5px 2px rgba(0,0,0,0.4);
    }
  }

  .foot-note {
    display: flex;
    font: normal 12px/115% Roboto;
    justify-content: center;
    padding: 20px;

    &:before {
      content: "*";
      color: $red;
      padding-right: 3px;
    }
  }

}