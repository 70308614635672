@import 'src/scss/global-variable';

.bill-container {

  .bill-table {
    width: 100%;
    display: table;
    text-align: left;
    font-size: 12px;
    line-height: 18px;
    min-height: 50px;
    padding: 5px 0;

    .bill-row {
      display: table-row;

      > div {
        display: table-cell;
      }

      .donator-bill {
        width: 70%;
        font-weight: bold;
      }

      .sum-bill {
        color: $red;
        font-weight: bold;
        font-size: 18px;
        text-align: right;
        padding-right: 5px;
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #EBEBEB;
  }
}