@import '../../global-variable';

.header {
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15), 0px 4px 2px rgba(0, 0, 0, 0.05);
  height: 90px;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 100;

  @media #{$medium-down} {
    width: 100%;
  }

  @media #{$small-down} {
    display: flex;
    flex-direction: row;
    height: 80px;
  }

  .toggle-mobile-menu {
    display: none;

    @media #{$small-down} {
      visibility: visible;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 13px;

      span {
        display: block;
        width: 30px;
        height: 2.5px;
        margin-bottom: 7px;
        background-color: $black;
        border-radius: 25%;
      }
    }
  }

  .title-header {
    width: $global_width;
    height: 100%;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;

    .logo-service {
      @media #{$small-down} {
        display: flex;
        justify-content: center;
      }
    }

    .logo-service img {
      width: 118%;

      @media #{$small-down} {
        width: 70%;
      }
    }

    .btn-header {
      align-items: center;
      display: flex;
      height: 100%;
      flex-direction: row;
      justify-content: center;

      @include remove-style-link;

      .search-link {
        display: flex;
        text-align: center;
        line-height: 28px;
        text-transform: uppercase;

        img {
          width: 32px;
          height: 32px;
          //padding-right: 40px;
        }

        @media #{$medium-down} {
          font-size: 0;
        }

        &:hover {
          text-decoration: none;
        }
      }

      span.line {
        width: 2px;
        height: 100%;
        background: radial-gradient(24.00px at 50% 50%, #000000 0%, rgba(0, 0, 0, 0) 100%);
        margin: 0 20px;
      }

      .logon-link {
        @include remove-style-link;

        &:hover {
          text-decoration: none;
          cursor: pointer;
        }

        padding-left: 35px;
        background: {
          image: url('../../../resources/images/enter.png');
          repeat: no-repeat;
          size: 28px;
        }

        & .lexemes {
          line-height: 28px;
          text-transform: uppercase;

          @media #{$medium-down} {
            font-size: 0;
          }
        }
      }

      .admin-link {
        @include remove-style-link;

        &:hover {
          text-decoration: none;
        }

        padding-left: 35px;
        background: {
          image: url('../../../resources/images/admin.png');
          repeat: no-repeat;
          size: 28px;
        }

        & a {
          line-height: 28px;
          text-transform: uppercase;
        }
      }
    }

    @media #{$medium-down} {
      width: 96%;
    }
  }


}