.program-links {
  display: flex;
  flex-direction: row;
  margin: 25px auto;
  width: $global_width;
  justify-content: flex-start;
  @media #{$medium-down} {
    width: 94%;
  }
  .links {
    text-decoration: none;
    .link {
      color: #000000;
      font-size: 18px;
      line-height: 200%;
    }
    .link-active {
      color: #0E2771;
      font-weight: bold;
      border-top: 2px solid #E73224;
      border-bottom: 2px solid #E73224;;
    }
  }
  .links:not(:last-child) {
    margin-right: 25px;
  }

}