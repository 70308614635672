@import '../../global-variable';

.links-fund {
  display: flex;
  flex-direction: row;
  margin: 25px auto;
  padding: 8px 10px;
  width: $global_width;
  justify-content: flex-start;
  z-index: 2;

  @media #{$medium-down} {
    width: 100%;
    margin: 142px -5px 25px auto;
  }

  @media #{$small-down} {
    display: none;
    height: 0;
    margin: 0;
    width: 0;
  }

  .links {
    text-decoration: none;
    .link {
      color: #000000;
      font-size: 18px;
      line-height: 200%;
      @media #{$small-down} {
        font-size: 14px;
        line-height: 150%;
      }
    }
    .link-active {
      color: #0E2771;
      font-weight: bold;
      border-top: 2px solid #E73224;
      border-bottom: 2px solid #E73224;;
    }
  }
  .links:not(:last-child) {
    margin-right: 25px;
  }

}