@import '../../global-variable';

.content-template {
  display: flex;
  flex-direction: column;
  background: $white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.06);
  width: auto;
  padding: 20px 50px;
  border-radius: 4px;
  @media #{$medium-down} {
    padding: 20px;
  }

  .date {
    display: flex;
    font: normal 12px/16px Roboto;
    color: $black;

    .updated {
      margin: 0 5px;
      font: italic 12px/16px Roboto;
      color: $dark-grey;
    }
  }

  .title-content {
    width: auto;
    font: bold 24px/28px Roboto;
    text-transform: uppercase;
    color: $black;
    padding: 20px 0;
  }

  .news-info-block {
    display: flex;
    flex-direction: row;

    @media #{$small-down} {
      flex-direction: column;
    }

    .logo-content {
      display: flex;
      flex-direction: column;
      border: 1px solid $dark-grey;
      box-sizing: border-box;
      width: 336px;
      height: 289px;
      float: left;
      @media #{$medium-down} {
        padding: 0;
        width: 100%;
      }

      .logo-container {
        display: flex;
        justify-content: center;
        width: 336px;
        height: 289px;
        @media #{$medium-down} {
          padding: 0;
          width: 100%;
        }
      }
    }

    .info-content {
      display: flex;
      flex-direction: column;
      padding-left: 30px;

      @media #{$medium-down} {
        width: 100%;
      }
      @media #{$small-down} {
        padding: 10px 0;
        width: 100%;
      }

      .description-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 100%;
        text-align: justify;
        font: normal 12px/18px Roboto;
        text-indent: 10px;
        color: $dark-more-gray;
        @media #{$medium-down} {
          display: flex;
          width: auto;
          height: auto;
          padding: 0;
        }
        @media #{$small-down} {
          display: flex;
          width: auto;
          height: auto;
          margin-left: 0px;
          float: left;
        }
      }

      .photo-block {
        display: block;
        border-radius: 4px;
        box-sizing: border-box;
        position: relative;
        justify-content: center;
        width: 70%;
        margin-right: 140px;
        margin-left: auto;
        padding: 20px 0;

        @media #{$medium-down} {
          width: 100%;
        }

        .awssld--organic-arrows {
          .awssld__wrapper {
            .awssld__container {
              .awssld__content {
                background-color: white;
                border-radius: 4px;

                img {
                  height: 100%;
                  border: 1px solid #7F7F7F;
                  border-radius: 4px;
                  box-sizing: border-box;
                  width: auto;
                  position: initial;
                }
              }
            }

            .awssld__controls {

            }
          }

          .awssld__bullets {
            bottom: -25px;
            z-index: 50;
          }
        }

        .share-story {
          display: flex;
          flex-direction: column;

          .title-share {
            font: bold 24px/36px Roboto;
            padding: 10px 0;
          }
        }
      }
    }
  }
}