@import "../../../global-variable";
@import "bannerScript";
@import "bannerDemo";


.banner-container {
  background-color: $grey;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  height: 470px;
  width: 700px;
  padding: 24px;
  @media #{$small-down} {
    flex-direction: column;
    overflow: scroll;
    padding: 0;
    height: 410px;
  }
}