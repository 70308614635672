@import "../../global-variable";

.reports-edit-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 25px;

  .upload-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 20px 0;

    input {
      display: none;
    }

    .upload-btn {
      @include btn-active;
      height: 40px;
      width: 204px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .table-container {
    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        padding-bottom: 10px;

        .th-1 {
          width: 2%;
        }

        .th-2 {
          width: 25%;
        }

        .th-3 {
          width: 15%;
        }

        .th-4 {
          width: 5%;
        }
      }

      tbody {
        border: 1px solid $grey-more-middle
      }
      td {
        border-bottom: 1px solid $grey-more-middle;
        padding: 10px;
        word-break: break-all;
      }

      td > .delete-btn {
        display: flex;
        justify-content: center;
        padding: 3px;
        height: 20px;

        &:hover {
          cursor: pointer;
        }
      }

      td > .edit-label {
        display: flex;
        flex-direction: row;
        input {
          width: 100%;
          height: 20px;
        }
      }

      .check-delete {
        background: rgba(127, 127, 127, 0.2);
        color: rgba(127, 127, 127, 0.3);

        td > a {
          color: rgba(127, 127, 127, 0.3);

          &:hover {
            cursor: default;
          }
        }

        input {
          background: rgba(127, 127, 127, -0.5);
          border-color: rgba(127, 127, 127, 0);

          &:hover, &:active, &:focus {
            border: none;
          }
        }
      }
    }
  }
}

.admin-page-footer .buttons-container .others-buttons-container {
  width: 100%;
  justify-content: inherit;
}