@import 'src/scss/global-variable';

.logon-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 320px;

  @media #{$screen} and (max-width: #{upper-bound($small-range)}) {
    left: 5%;
    top: 20px !important;
    width: 90% !important;
  }

  .logon-title {
    @include title-m;
    text-align: center;
    line-height: 23px;
    margin-top: 15px;
    padding-bottom: 30px;
  }

  #choice-auth {
    .form-group {
      width: 100%;
      .choice-auth {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .choice {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }

  #sing-in-form {
    .input-logon {
      position: relative;
      padding: 15px 0;

      .show-icon {
        position: relative;
        top: -32px;
        left: 90%;
        width: 16px;
        height: 13px;
        img {
          width: 16px;
          height: 12.25px;
        }
      }
      input {
        font-size: 14px;
        line-height: 146%;
        height: 25px;
        padding: 7px;
        color: #555;
        border-radius: 4px;
        border: 1px solid #ccc;
        transition: border-color .3s;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        filter: none;
        -webkit-box-shadow: inset 0 0 0 50px #fff;
        -webkit-text-fill-color: #000;
        min-width: 95%;
        &:focus {
          border: 1px solid $blue;
          outline: none;
        }

        &:hover {
          cursor: pointer;
        }
      }

      label {
        position: absolute;
        margin-top: -10px;
        margin-left: 4px;
        font-size: 12px;
        line-height: 19px;
        color: #ccc;
        padding: 0 2px;
        background-color: $white;
        z-index: 100;
      }
    }

    button {
      @include btn-active;
      width: 100%;
    }

    button[disabled] {
      background-color: #cccccc;
      border: 2px solid #7F7F7F;

      &:hover {
        background-color: #cccccc;
        cursor: default;
        box-shadow: none;
      }
    }
  }
}

.fund-list-for-auth {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 320px;

  @media #{$screen} and (max-width: #{upper-bound($small-range)}) {
    left: 5%;
    top: 20px !important;
    width: 90% !important;
  }

  .title {
    @include title-m;
    text-align: center;
    line-height: 23px;
    margin-top: 15px;
    padding-bottom: 30px;
  }

  .fund-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    .fund-auth {
      display: flex;
      flex-direction: row;
      padding: 10px 0;
    }
  }
  button {
    @include btn-active;
    width: 100%;
    padding: 3px;
  }

  button[disabled] {
    background-color: #cccccc;
    border: 2px solid #7F7F7F;

    &:hover {
      background-color: #cccccc;
      cursor: default;
      box-shadow: none;
    }
  }

}

input[type="radio"] {
  display:none;
}
input[type="radio"] + label::before {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid $blue;;
  background-color: $white;
  content: "";
  display: block;
  float: left;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
  }
}
input[type="radio"]:checked+label::before {
  background-color: $blue;
  content: "\2713";
  text-align: center;
  font-size: 14px;
  color: $white;
  &:hover {
    cursor: pointer;
  }
}
