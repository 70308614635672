@import "../../global-variable";

  .search-result {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;

    .result {
      @include remove-style-link;
      a {
        .search-result-title {
          font-size: 18px;
          font-weight: bold;
          line-height: 25px;
        }

        .search-result-text {
          font-size: 12px;
          line-height: 20px;

          span {
            background-color: $success-color;
          }
        }

        &:hover {
          color: $red;
          text-decoration: none;
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid #EBEBEB;
    }
  }

