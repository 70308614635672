@import "../../global-variable";

.captcha {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-end;

  .container {
    display: flex;
    flex-direction: column;
    width: 200px;

    .svg-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding-top: 10px;

      form {
        width: 100%;
        input {
          font-size: 12px;
          padding: 5px;
          color: #555;
          border-radius: 4px;
          border: 1px solid #ccc;
          transition: border-color .3s;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          width: 100%;
          height: 20px;
        }
      }
    }
  }


  .regenerate-captcha {
    background: {
      image: url('../../../resources/images/autorenew.png');
      repeat: no-repeat;
      size: 24px;
    }
    margin: 5px 20px;
    height: 24px;
    width: 24px;


    &:hover {
      cursor: pointer;
    }
  }

}