.about-fund-container {
  background-color: #FFFFFF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px 15px;
  @media #{$medium-down} {
    border-radius: 0;
  }
}