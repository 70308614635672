@import "../../global-variable";

.fund-settings-container {
  label {
    font-weight: bold;
    font-size: 12px;
    line-height: 19px;
    color: #000000;
    opacity: 0.6;
    padding-left: 7px;
  }
  input, select {
    border: 1px solid #bfbfbf;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 7px 5px;
  }

  .categories-edit {
    margin-bottom: 10px;
    div {
      cursor: pointer;
    }
  }

  .settings-fund-edit {
    width: fit-content;

    .default-currency-edit {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      label {
        margin-right: 5px;
      }
      select {
        cursor: pointer;
      }
    }

    .count-last-operation-edit {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      label {
        margin-right: 5px;
      }
    }

    .checkbox-form {
      margin-bottom: 10px;

      @media #{$small-down} {
        width: 100%;
      }
    }

    .checkbox-form label::after {
      top: 0;
    }
  }

}