@import "../../global-variable";
@import "deleteNewsButton";

.edit-news-container {
  height: 100%;
  padding: 10px 25px;

  .edit-container {

    .admin-edit-list {
      margin: 16px 0;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid rgba(190, 30, 26, 0.2);
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      list-style-type: none;
      padding: 0;

      .default-edit-tab {
        color: #BE1E24;
        opacity: 0.4;
        padding: 6px 16px;
        cursor: pointer;
      }

      .selected-edit-tab {
        color: #FFFFFF;
        background-color: #BE1E24;
        opacity: 1;
        outline: none;
      }
    }

    form {
      .block-news-information-container {
        .title-container {
          margin-bottom: 20px;
        }

        .title-container, .description-container {
          display: flex;
          flex-direction: column;

          label {
            font-weight: bold;
            font-size: 12px;
            line-height: 19px;
            color: #000000;
            opacity: 0.6;
            padding-left: 7px;

            &:after {
              content: "*";
              color: $red;
              opacity: 1;
              padding-left: 3px;
            }
          }
          input, textarea {
            border: 1px solid #bfbfbf;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 7px 5px;
          }
          textarea {
            min-height: 160px;
          }
        }
        .date-container {
          font-weight: bold;
          font-size: 12px;
          line-height: 19px;
          color: #000000;
          opacity: 0.6;
          padding-left: 7px;
          padding-top: 10px;
        }
      }
      .recommended-container {
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 20px;
        text-align: center;

        &:before {
          content: "*";
          color: $red;
          padding-right: 3px;
        }
      }
    }
  }
}
.admin-page-footer .buttons-container .others-buttons-container {
  width: 100%;
  justify-content: inherit;
}