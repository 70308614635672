@import "../../../global-variable";

.banner-script-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  width: 58%;
  padding-right: 2%;
  @media #{$small-down} {
    width: 96%;
    margin: auto;
    padding: 0;
  }

  .bs-title {
    font: normal 14px/156% Roboto;
    @media #{$small-down} {
      margin-top: 25px;
    }
  }

  .bs-script {
    & .script-container {
      background-color: #fff;
      border: 1px solid #D6D6D6;
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 14px;
      height: 280px;
      margin-top: 10px;
      margin-bottom: 10px;
      overflow: scroll;
      width: 100%;
    }
  }

  .bs-button-copy {
    & button {
      background-color: #BE1E24;
      border: none;
      width: 100%;
      height: 40px;
      color: #fff;
      box-shadow: 0px 4px 8px #fce9e9, 0px 4px 4px rgba(0,0,0,0.08);
      border-radius: 4px;
      font-size: 16px;
      line-height: 100%;
      margin-bottom: 15px;

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.58);
      }
    }
  }
}