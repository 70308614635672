@import "../../global-variable";

.sort-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /*@media #{$medium-down} {
    display: none;
  }*/
  @media #{$small-down} {
    width: 100%;
  }

  .sort-title {
    @include text-m;

    margin-right: 7px;

    @media #{$small-down} {
      display: none;
    }
  }

  .sort {
    width: 300px;
    margin-left: 10px;

    @media #{$small-down} {
      margin-bottom: 10px;
      margin-left: 0;
      width: 100%;
    }
  }
}


