@import "../../global-variable";

.logo-circle-container {
  border-radius: 4px;
  height: 215px;
  width: 266px;
  position: relative;

  .image-position {
    height: 150px;
    width: 100%;
    position: absolute;
    border-radius: 50%;
    box-sizing: border-box;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;

    .logo-circle {
      height: 100%;
      width: 100%;
      object-fit: contain;

      @media #{$medium-down} {
        min-width: auto;
      }
    }
  }
}
