@import "../../global-variable";

.share-container {
  padding: 0;
  a {
    background: {
      repeat: no-repeat;
      size: cover;
    };
    display: inline-block;
    height: 32px;
    margin-right: 7px;
    width: 32px;

    &:hover {
      cursor: pointer;
    }
    &.vkontakte {
      background-image: url('../../../resources/images/logos/vk.png');
    }
    &.ok {
      background-image: url('../../../resources/images/logos/ok.png');
    }
    &.email {
      background-image: url('../../../resources/images/logos/email.png');
    }
    &.telegram {
      background-image: url('../../../resources/images/logos/telegram.png');
    }
    &.youtube {
      background-image: url('../../../resources/images/logos/youtube.png');
    }
    &.skype {
      background-image: url('../../../resources/images/logos/skype.png');
    }
    &.shareIcon {
      background-image: url('../../../resources/images/logos/banner.png');
    }
  }
}