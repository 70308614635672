@import "../../global-variable";

.notification-container {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .notification {
    position: fixed;
    left: 50%;
    top: 30%;
    max-width: 100%;
    transform: translate(-50%, -30%);

    .notification-flex-container {
      display: flex;
      flex-direction: column;

      .notification-content {
        display: flex;
        flex-direction: column;
        background-color: $white;
        border-radius: 15px;
        padding: 15px;
        margin-top: 10px;

        .notification-message {
          @include text-m;
          white-space: pre-wrap;
          color: $dark-more-gray;
          //margin-top: 22px;
        }

        .notification-button {
          padding-top: 15px;
          text-align: center;

          button {
            @include box-shadow;
            color: $white;
            //border: 1px solid $dark-grey;
            border-radius: 4px;
            box-sizing: border-box;
            min-height: 30px;
            font: 16px/156% Roboto;
            min-width: 100px;

            &:hover {
              cursor: pointer;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.58);
            }
          }

          .warning {
            background-color: $red;
          }

          .information {
            background-color: $grey-middle
          }

          .success {
            background-color: $success-color
          }
        }
      }
    }

    .warning {
      border: 5px solid $red
    }
    .information {
      border: 5px solid $grey-middle
    }

    .success {
      border: 5px solid $success-color
    }
  }
}

@mixin _3d-borders($color, $width: 2px, $scale: 2) {
  border: $width solid;
  border-color: lighten($color, $scale) darken($color, $scale);
}

.cookies-notification-container {
  position: fixed;
  bottom: 0;
  height: 10%;
  width: 100%;
  z-index: 10000;

  .notification {
    display: flex;
    justify-content: flex-end;
    height: 100%;

    .notification-flex-container {
      @include _3d-borders($grey);
      background-color: $grey;
      display: flex;
      flex-direction: column;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
      border-radius: 2px;
      width: 100%;

      .notification-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        height: 20%;

        .notification-close {
          padding-right: 3px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .notification-content {
        display: flex;
        flex-direction: row;

        .notification-message {
          @include text-xs;
          padding: 0 8px;
        }

        .notification-button {
          padding: 10px;
          button {
            color: $white;
            background-color: darken($grey, 25);
            border-radius: 4px;
            border: none;
            width: 100px;
            height: 25px;
            font-size: 16px;
            font-weight: bold;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
