@import "../../global-variable";
@import "deleteProgramButton";

.edit-program-container {
  height: 100%;
  padding: 10px 25px;
}

.admin-page-footer .buttons-container .others-buttons-container {
  width: 100%;
  justify-content: inherit;
}