@import "../../global-variable";

.logo-container {
  border-radius: 4px;
  height: 215px;
  width: 300px;
  position: relative;

  .blur-container {
    width: 100%;
    height: 100%;
    -webkit-filter: blur(15px);
    filter: blur(15px);
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
    position: absolute;
    z-index: 1;
  }

  .image-position {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      height: 100%;
      width: 100%;
      object-fit: contain;

      @media #{$medium-down} {
        min-width: auto;
      }
    }

  }
}
