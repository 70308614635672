@import "../../global-variable";
@import "addProgramButton";
@import "programInfo";
@import "editPriorityButton";

.admin-program-list {
  height: 100%;
  padding: 10px 25px;

  .tab-list {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(190, 30, 26, 0.2);
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    list-style-type: none;
    padding: 0;

    .default-tab {
      color: $red;
      opacity: 0.4;
      padding: 6px 16px;

      &:hover {
        cursor: pointer;
      }
    }

    .selected-tab {
      color: $white;
      background-color: $red;
      opacity: 1;
    }
  }

  .tab-panel {
    display: flex;
    flex-wrap: wrap;
    justify-content: inherit;
    max-height: 645px;
    overflow-y: auto;
  }
}