@import '../../global-variable';

.team-components {
  padding: 10px 0;

  .members {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media #{$small-down} {
      grid-template-columns: 1fr;
    }

    .member-container {
      display: flex;
      flex-direction: column;
      padding: 20px;

      .photo {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 125px;
          height: 125px;
          border-radius: 100px;
          box-shadow: 0 0 7px #666;
        }
      }
      .name {
        text-align: center;
        font-size: 16px;
        line-height: 28px;
        padding-top: 10px;
      }

      .position {
        text-align: center;
        font-size: 12px;
        line-height: 140%;
      }
    }
  }
}