@import "../../global-variable";

.donate-form-container {
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 16px;

  .donate-form-header {
    @include title-m;
  }

  .donate-form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .input-container {
      display: flex;
      flex-direction: column;

      label {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #000000;
        padding: 5px 0 0 0;
      }

      label[for=donation-sum]:after {
        content: '*';
        color: #BE1E24;
        padding-left: 3px;
      }

      input, textarea {
        padding: 5px 0 5px 0;
        border: none;
        border-bottom: 1px solid #EBEBEB;
        color: #037AFF;
        font-size: 17px;
        line-height: 20px;
      }
      input:focus{
        outline: none;
      }
      input::-webkit-input-placeholder {
        color: #D6D6D6;
      }

      #name-project {
        border: none;
        border-bottom: 1px solid #EBEBEB;;
        > div {
          border-width: 0;
        }
        span {
          width: 0;
        }
        .css-tlfecz-indicatorContainer {
          border: 1px solid #037AFF;
          border-radius: 50%;
          color: #037AFF;
          padding: 1px;
          .css-tlfecz-indicatorContainer {
            svg {
              width: 14px !important;
              height: 14px !important;
            }
          }
        }
      }
      #name-project:focus {
        outline: none;
      }
    }

    .consent-container {
      display: flex;
      flex-direction: row;
      padding: 10px 0 10px 0;
      font-weight: 300;

      input[type="checkbox"] {
        display:none;
      }
      input[type="checkbox"] + label::before {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #BE1E24;;
        background-color: #fff;
        content: "";
        display: block;
        float: left;
        margin-right: 5px;
        &:hover {
          cursor: pointer;
        }
      }
      input[type="checkbox"]:checked+label::before {
        background-color: #BE1E24;
        content: "\2713";
        text-align: center;
        font-size: 14px;
        color: #FFFFFF;
        &:hover {
          cursor: pointer;
        }
      }

      label {
        a {
          @include remove-style-link;
        }

        font-size: 10px !important;
        line-height: 16px !important;
      }
    }

    .payment {
      @include btn-active;
      font-size: 18px;
      margin-bottom: 15px;
      height: 48px;
      width: 100% !important;
      &:hover {
        cursor: pointer;
      }
    }
    .payment[disabled] {
      background-color: #D6D6D6 !important;
    }
  }

  .about-container {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #EBEBEB;
    padding-top: 8px;
    img {
      margin-right: 16px;
    }
    .about {
      a {
        @include remove-style-link;
      }

      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
    }
  }
}