@import "../../global-variable";

.add-new-program {
  a {
    @include btn-active;
    @include remove-style-link;

    align-items: center;
    display: flex;
    color: $white;
    justify-content: center;
    font-size: 16px;
    height: 40px;
    line-height: 100%;
    text-decoration: none;
    padding: 0px 10px;

    img {
      width: 24px;
      padding-right: 5px;
    }

    &:hover {
      text-decoration: none;
    }
  }
}