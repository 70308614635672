@import "../../../global-variable";

.banner-demo-container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 40%;
  @media #{$small-down} {
    width: 96%;
    margin-bottom: 25px;
    margin-top: 25px;
  }

  .banner-demo {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #D6D6D6;
    height: 400px;
    width: 240px;
    @media #{$small-down} {
      height: 100%;
      width: 70%;
    }

    .banner-card {
      height: 50%;
      position: relative;

      .bd-blur {
        width: 100%;
        height: 100%;
        -webkit-filter: blur(15px);
        filter: blur(15px);
        -webkit-clip-path: inset(0);
        clip-path: inset(0);
        position: absolute;
        z-index: 1;
      }

      .bd-image {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 2;
        display: flex;
        justify-content: center;
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      .bd-logo {
        position: absolute;
        top: 0;
        height: 36px;
        padding: 8px;
        z-index: 3;

        img {
          height: 100%;
        }
      }
    }

    .banner-info {
      padding: 0 10px;

      .bd-title {
        font: bold 18px/25px $font-family-roboto;
      }

      .bd-description {
        font: normal 12px/18px $font-family-roboto;
      }

      .bd-exp-date {
        font: normal 14px/18px $font-family-roboto;
        margin: 5px 0;
      }

      .bd-raised-funds {
        display: flex;
        flex-direction: row;
        margin: 5px 0;

        .raised-lexemes {
          font: normal 14px/18px $font-family-roboto;
        }

        .raised-total > span{
          color: $red;
          font: bold 16px $font-family-roboto;
          margin-left: 5px;
        }
      }

      .bd-button {
        background-color: #fff;
        border: 1px solid #D6D6D6;
        border-radius: 4px;
        height: 30px;
        text-align: center;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          font: bold 14px/16px $font-family-roboto;
          color: #7F7F7F;
          text-decoration: none;
          width: 100%;
        }
      }
    }

    .banner-powered {
      font: 10px/12px $font-family-roboto;
      margin: 8px 10px;
    }
  }




}