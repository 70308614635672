@import "../../global-variable";

.registration-button {
  margin-top: 40px;
  background: #BE1E24;
  border-radius: 4px;
  width: 304px;
  height: 52px;
  border: none;
  color: $white;
  line-height: 21px;
  font-size: 18px;
  text-align: center;

  &:hover {
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.4);
    cursor: pointer;
  }
}