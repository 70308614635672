@import "../../global-variable";

.block-container-common-fund-page {
  background-color: #FFFFFF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding-top: 6px;

  .title-sort-container {
    display: flex;
    justify-content: flex-end;
  }
}