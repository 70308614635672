@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  src: url('../resources/fonts.Roboto/Roboto-Light.ttf') format('truetype'),
  url('../resources/fonts.Roboto/Roboto-Light.eot') format('embedded-opentype'),
  url('../resources/fonts.Roboto/Roboto-Light.woff') format('woff'),
  url('../resources/fonts.Roboto/Roboto-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  src: url('../resources/fonts.Roboto/Roboto-LightItalic.ttf') format('truetype'),
  url('../resources/fonts.Roboto/Roboto-LightItalic.eot') format('embedded-opentype'),
  url('../resources/fonts.Roboto/Roboto-LightItalic.woff') format('woff'),
  url('../resources/fonts.Roboto/Roboto-LightItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  src: url('../resources/fonts.Roboto/Roboto-Regular.ttf') format('truetype'),
  url('../resources/fonts.Roboto/Roboto-Regular.eot') format('embedded-opentype'),
  url('../resources/fonts.Roboto/Roboto-Regular.woff') format('woff'),
  url('../resources/fonts.Roboto/Roboto-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  src: url('../resources/fonts.Roboto/Roboto-Italic.ttf') format('truetype'),
  url('../resources/fonts.Roboto/Roboto-Italic.eot') format('embedded-opentype'),
  url('../resources/fonts.Roboto/Roboto-Italic.woff') format('woff'),
  url('../resources/fonts.Roboto/Roboto-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: url('../resources/fonts.Roboto/Roboto-Bold.ttf') format('truetype'),
  url('../resources/fonts.Roboto/Roboto-Bold.eot') format('embedded-opentype'),
  url('../resources/fonts.Roboto/Roboto-Bold.woff') format('woff'),
  url('../resources/fonts.Roboto/Roboto-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  src: url('../resources/fonts.Roboto/Roboto-BoldItalic.ttf') format('truetype'),
  url('../resources/fonts.Roboto/Roboto-BoldItalic.eot') format('embedded-opentype'),
  url('../resources/fonts.Roboto/Roboto-BoldItalic.woff') format('woff'),
  url('../resources/fonts.Roboto/Roboto-BoldItalic.woff2') format('woff2');
}
