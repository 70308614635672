@import '../../global-variable';
@import "searchContainer";

.search-page-container {
  background-color: $grey;
  height: 100%;
  width: 100%;

  .bg-photo-search-page {
    background: {
      image: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 45%), url(../../../resources/images/bg-search.png);
      position: 30% 80%;
      size: cover;
    }
    height: 440px;
    width: 100%;
  }


}