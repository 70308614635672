@import "../../global-variable";

.team-edit-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 25px;

  .upload-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 20px 0;

    input {
      display: none;
    }

    .upload-btn {
      @include btn-active;
      height: 40px;
      width: 204px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .table-container {
    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        padding-bottom: 10px;
      }

      tbody {
        border: 1px solid $grey-more-middle;
      }

      td:first-child {
        width: 25px;
        .dragging {
          background: {
            image: url('../../../resources/images/dragging.png');
            size: cover;
            repeat: no-repeat;
          };
          height: 15px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      td {
        border-bottom: 1px solid $grey-more-middle;
        padding: 10px;

        .member-photo {
          width: 80px;
          height: 80px;
          border-radius: 100px;
          box-shadow: 0 0 7px #666;
        }

        .editing {
          display: flex;
          flex-direction: column;

          .input-container {
            display: flex;
            flex-direction: column;
          }
        }
      }

      td > .delete-btn {
        display: flex;
        justify-content: center;
        padding: 3px;
        height: 20px;

        &:hover {
          cursor: pointer;
        }
      }


      .check-delete {
        background: rgba(127, 127, 127, 0.2);
        color: rgba(127, 127, 127, 0.3);

        td > img {
          opacity: 0.2;
        }

        input {
          background: rgba(127, 127, 127, -0.5);
          border-color: rgba(127, 127, 127, 0);
          color: rgba(127, 127, 127, 0.3);

          &:hover, &:active, &:focus {
            border: none;
          }
        }
      }
    }
  }

}

.admin-page-footer .buttons-container .others-buttons-container {
  width: 100%;
  justify-content: inherit;
}