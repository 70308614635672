@import "../../global-variable";

.account {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .account-info {
    display: flex;
    align-items: center;
    flex-direction: row;
    color: $white;
    margin: 0 15px;

    .account-img {
      height: 42px;
      width: 42px;
      img {
        border-radius: 42px;
        height: 100%;
        width: 100%;
      }
    }

    .account-name {
      margin-left: 7px;
    }

    .open-menu {
      width: 8px;
      height: 8px;
      border-bottom: 1px solid $white;
      border-right: 1px solid $white;
      transform: rotate(45deg);
      margin-left: 8px;
    }
  }

  .account-menu-wrapper {
    display: none;
    position: absolute;
    top: 59px;
    right: 0;
    padding-top: 25px;

    @media (max-width: 600px) {
      width: auto;
      right: -63px;
      left: auto;
    }

    .account-menu {
      display: flex;
      z-index: 5;
      position: relative;
      flex-direction: column;
      justify-content: space-around;
      box-sizing: border-box;
      padding-top: 16px;
      padding-bottom: 8px;
      width: 214px;
      border-radius: 4px;
      background: #C4C4C4;
      box-shadow: 0 4px 8px rgba($black, 0.08);
      @media (max-width: 600px) {
        width: 190px;
        right: 16px;
      }

      @include remove-style-link;
      a {
        &:hover {
          cursor: pointer;
          background: rgba($black, 0.08);
          text-decoration: none;
        }
      }

      &:before {
        position: absolute;
        top: 0;
        left: 70%;
        display: block;
        width: 20px;
        height: 20px;
        background: #C4C4C4;;
        content: '';
        transform: rotate(45deg) translateX(-50%);
        @media (max-width: 600px) {
          left: 59%
        }
      }

      .field-menu {
        @include text-l;
        color: $white;
        display: flex;
        align-items: center;
        height: 40px;
        padding-left: 20px;

        &:hover {
          cursor: pointer;
          background: rgba($black, 0.08);
          text-decoration: none;
        }
      }
    }
  }
}

.account:hover .account-menu-wrapper, .account-menu:hover {
  display: block;
  cursor: pointer;
}

.account .account-menu-wrapper .account-menu:hover {
  text-decoration: none;
}