@import "src/scss/global-variable";
@import "imageContainer";

.gallery-container {
  display: flex;
  flex-direction: row;

  .image-logo-container {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 10px;
    height: fit-content;

    .image-logo {
      @include text-m;
      height: initial;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    .download-container {
      margin-top: 20px;
      text-align: center;
      .custom-file-upload {
        border: 1px solid $blue;
        border-radius: 4px;
        color: $blue;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;

        input[type="file"] {
          display: none;
        }
      }
    }
    .message-gallery {
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: 20px;
      text-align: center;
      &:before {
        content: "*";
        color: $red;
        padding-right: 3px;
      }
    }
  }

  .edit-images-container {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: 20px;

    .all-images {
      display: grid;
      grid-template-columns: repeat(auto-fit, 220px);
      grid-gap: 32px;
      width: 100% !important;
    }

  }
}