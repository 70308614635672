@import "../../global-variable";

.admin-page-footer {
  background: #F5F5F5;

  border: 1px solid #D6D6D6;
  height: 80px;
  width: 100%;

  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px;

    .others-buttons-container {
      display: flex;
      flex-direction: row;
      padding: 0 10px;
    }
  }
}
