@import "../../global-variable";

.program-container {
  @include remove-style-link;

  @media #{$medium-down} {
    padding: 0;
  }
  @media #{$small-down} {
    border-bottom: 1px solid #D6D6D6;
  }

  .program-block-container {
    height: 100%;
    padding: 10px 15px;

    a {
      display: flex;
      flex-direction: column;
      justify-content: start;
      width: 100%;
      height: 92%;
      @media #{$medium-down} {
        padding: 0;
      }
      @media #{$small-down} {
        align-items: center;
      }

      .program-block {
        height: 100%;

        .project-photo {
          position: relative;

          @media #{$medium-down} {
            padding: 0;
          }

          .logo-container {
            width: 100%;

            @media #{$medium-down} {
              padding: 0;
              width: 100%;
            }
            @media #{$small-down} {
              width: 100%;
            }

            .image-position {
              width: 100%;
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .program-info {
          position: relative;
          top: -40px;
          z-index: 2;
          height: 355px;
          background-color: #EBEBEB;
          margin: 0 15px;
          border-radius: 4px;

          @media #{$medium-down} {
            margin: 0 10px 0 10px;
          }
          @media #{$small-down} {
            margin: 0 10px 0 10px;
            height: 388px;
          }

          .info-content {
            display: flex;
            flex-direction: column;
            padding: 15px;

            .title {
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px;
              padding: 10px 0;
              text-transform: uppercase;
            }

            .info {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 28px;
              height: 100%;
              width: 100%;
              color: $dark-more-gray;
            }
          }

          .calc-totals-block {
            @media #{$medium-down} {
              padding: 0;
            }
          }
        }

        .charity-is-over {
          display: flex;
          flex-direction: column;
          margin: 0px 6px 0px 120px;
          text-align: end;
          @include text-xs;

          @media #{$medium-down} {
            margin: auto;
          }
        }
      }

      &:hover {
        cursor: pointer;
        text-decoration: none;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.4);
      }
    }
  }

  &:hover {
    text-decoration: none;
  }
}