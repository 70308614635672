@import '../../global-variable';

.description-fund {
  font: normal 16px/28px Roboto;
  @media #{$medium-down} {
    width: 94%;
    margin: auto;
    font-size: 14px;
    line-height: 20px;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.carousel-fund {
  padding: 10px 100px 20px 100px;
  @media #{$small-down} {
    width: 94%;
    padding: 10px 0;
    margin: 0 auto 30px auto;
  }
}