@import "../../global-variable";

.content-block-template {
  display: flex;
  flex-direction: row;
  margin: auto;
  min-height: 320px;

  @media #{$medium-down} {
    flex-direction: column;
  }

  .photo-description-block {
    background-color: $white;
    box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1), 0 3px 7px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: relative;
    width: 55%;

    @media #{$medium-down} {
      width: 100%;
    }
    .photo {
      display: flex;
      justify-content: center;
      font-size: 0;
      margin: 15px;

      .photo-of-fund {
        border: none;
        width: 400px;
      }
    }
    .description {
      margin: 15px;
      font-size: 12px;
      line-height: 18px;
      .title-lexeme {
        font-weight: bold;
        font-size: 22px;
        line-height: 36px;
      }
    }
  }

  .info-statistic-block {
    width: 45%;
    @media #{$medium-down} {
      width: 100%;
    }
    > div {
      margin: 20px;
    }
    .title-template {
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
    }
    .logo-name-fund {
      @include remove-style-link;
      align-items: center;
      display: flex;
      img {
        width: 44px;
        margin-right: 7px;
      }
      a > div {
        font-size: 16px;
        line-height: 27px;
      }
    }
    .calc-totals {
      p {
        margin: 0;
      }
    }
    .button-donate {
      .button-donate-container {
        .btn-donate {
          min-height: 46px;
          background-color: #BE1E24;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .not-donate-message {
      text-align: center;
    }
    .share-story, .recent-supporters {
      display: flex;
      flex-direction: column;

      .title-share {
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
}