@import "../../global-variable";

.error-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  margin: auto;

  @media #{$small-down} {
    flex-direction: column;
  }

  .error-img {
    img {
      background-size: cover;
      background-repeat: no-repeat;
      height: auto;
      width: 260px;
      @media #{$small-down} {
        width: 160px;
      }
    }
  }

  .error-msg {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    @media #{$small-down} {
      font-size: 20px;
      line-height: 24px;
    }
    p {
      margin: 0;
    }
  }
}
