.awssld--organic-arrows {
  .awssld__wrapper {
    .awssld__container {
      .awssld__content {
        background-color: white;
        border-radius: 4px;
        img {
          height: 100%;
          border: 1px solid #7F7F7F;
          border-radius: 4px;
          box-sizing: border-box;
          width: auto;
          position: initial !important;
        }
      }
    }
    .awssld__controls {

    }
  }

  .awssld__bullets {
    bottom: -25px;
    z-index: 50;

    button {
      height: 15px;
      width: 15px;
      background: transparent;
      background: {
        position: -1px 0;
        image: url('../../resources/images/slick/default-pointer.png');
      }
      &:before {
        content: none;
      }
    }

    .awssld__bullets--active {
      transform: none;
      background: {
        image: url('../../resources/images/slick/default-pointer_active.png');
      }
    }
  }
}