@import "../../global-variable";

.popup-widget {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .popup-container {
    position: fixed;
    left: 50%;
    top: 37%;
    max-width: 100%;
    transform: translate(-50%, -30%);

    .popup-flex-container {
      display: flex;
      flex-direction: column;

      .popup-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 0;

        .popup-title {
          color: $white;
          font-size: 22px;
        }

        .popup-cancel {
          border-radius: 50%;
          height: 44px;
          width: 44px;
          position: absolute;
          outline: none;
          right: 0;
          background: transparent url(../../../resources/images/close.png) no-repeat center;
          -webkit-appearance: initial;
          z-index: 10;

          &:hover {
            cursor: pointer;
          }
        }

      }

      .popup-content {
        display: flex;
        background-color: $white;
        border-radius: 15px;
      }
    }
  }
}