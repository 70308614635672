@import "../../global-variable";
@import "addNewsButton";
@import "newsInfo";

.admin-news-list {
  height: 100%;
  padding: 10px 25px;

  .select-news-block {
    z-index: 50;
    display: flex;
    flex-direction: row;
    justify-content: right;
    flex: 1;


    .css-2b097c-container {
      z-index: 50;
    }
  }

  .admin-news-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: inherit;
    max-height: 645px;
    overflow-y: auto;
  }


}
