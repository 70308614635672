@import '../../global-variable';

.mobile-menu-open {
  transform: translateX(0);
}

.mobile-menu-close {
  transform: translateX(-100%);
}

.mobile-menu {
  position: absolute;
  top: 80px;
  left: 0px;
  bottom: 0px;
  z-index: 99;
  display: block;
  width: $global_width;
  max-width: 100%;
  margin-top: 0px;
  padding-top: 50px;
  padding-left: 35px;
  align-items: stretch;
  background-color: $dark-more-gray;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-transform: uppercase;

  .links-mobile {
    @include remove-style-link;
    display: flex;
    flex-direction: column;
    color: $white;
    &:hover {
      text-decoration: none;
    }

    .link-mobile {
      @include remove-style-link;
      font: normal 18px/21px Roboto;
      padding: 15px 0;
      color: $white;

      &:hover {
        text-decoration: none;
      }

      .sub-links {
        @include remove-style-link;
        display: flex;
        flex-direction: column;
        font: normal 14px/150% Roboto;
        padding-left: 20px;
        a {
          color: $white;
          padding: 10px 0;
        }
        &:hover {
          text-decoration: none;
        }
      }

    }

    .active {
      font-weight: bold;
      color: $hex
    }
  }
}


