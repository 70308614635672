@import "../../global-variable";

.links {
  .phone-fund {
    @include text-m;
    padding-top: 5px;
    color: $dark-more-gray;
  }

  .link-fund {
    padding-top: 5px;
    & a {
      @include text-xs;

      color: $red;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  .social-network {
    padding-top: 10px;
    a {
      background: {
        repeat: no-repeat;
        size: cover;
      };
      display: inline-block;
      height: 32px;
      filter: alpha(Opacity=50);
      margin-right: 7px;
      opacity: 0.5;
      width: 32px;

      &:hover {
        cursor: pointer;
        opacity: 1; /* Полупрозрачный фон */
        filter: alpha(Opacity=1); /* Прозрачность в IE */
      }
      &.vkontakte {
        background-image: url('../../../resources/images/logos/vk.png');
      }
      &.ok {
        background-image: url('../../../resources/images/logos/ok.png');
      }
      &.email {
        background-image: url('../../../resources/images/logos/email.png');
      }
      &.telegram {
        background-image: url('../../../resources/images/logos/telegram.png');
      }
      &.youtube {
        background-image: url('../../../resources/images/logos/youtube.png');
      }
      &.skype {
        background-image: url('../../../resources/images/logos/skype.png');
      }
    }
  }
}
