@import "../../global-variable";

  .admin-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(0deg, rgba(3, 122, 255, 0.04), rgba(3, 122, 255, 0.04)), linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #FFFFFF;
    height: 72px;
    width: 100%;
    @media #{$small-down} {
      height: 80px;
    }

    .logo-service {
      background: {
        image: url(../../../resources/images/logoMobiCashDB_red.svg);
        repeat: no-repeat;
        size: contain;
      }
    ;
      height: 56px;
      margin: 15px;
      width: 100%;
    }

    .account-logout-container {
      display: flex;
      flex-direction: row;
    }
  }
