@import "../../global-variable";

.donate-result-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  margin: auto;

  @media #{$small-down} {
    flex-direction: column-reverse;
    margin-top: 100px;
    width: 100%;
  }

  .img-result{
    max-width: 640px;

    img {
      @media #{$small-down} {
        width: 100%;
      }
    }
  }

  .info-text-result {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    line-height: 30px;

    .title-result {
      //@include title-m
      margin-bottom: 15px;
    }
    .link-return {
      a {
        color: #BE1E24;
        text-decoration: underline;
      }
      a:hover {
        text-decoration: none;
      }
    }
  }
}