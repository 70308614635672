@import "src/scss/global-variable";

.image-container {
  position: relative;
  border: 5px solid #e6e6e6;
  box-sizing: border-box;
  width: auto;
  height: 208px;
  border-radius: 4px;
  display: flex;
  align-items: center;

  .blur-container {
    background-color: $grey-more-middle;
    width: 100%;
    height: 100%;
    -webkit-filter: blur(15px);
    filter: blur(15px);
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
    position: absolute;
    z-index: 1;
  }

  .image-position {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    .image {
      height: 100%;
      width: 100%;
      object-fit: contain;

      @media #{$medium-down} {
        min-width: auto;
      }
    }

  }

  .btn-delete-position {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 3;
    &:hover {
      cursor: pointer;
    }

    .btn-delete-img {
      display: flex;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: $white;
      height: 100%;
      justify-content: center;
      padding: 2px;
      img {

      }
    }
  }

  .btn-set-logo-position {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 3;

    &:hover {
      cursor: pointer;
    }

    .btn-set-logo {
      border: 1px solid $blue;
      background-color: $white;
      color: $blue;
      box-sizing: border-box;
      border-radius: 4px;
      font: normal 14px Roboto;
      text-align: center;
      padding: 4px;
    }
  }



}
.image-container-logo {
  border: 3px solid $blue;
}