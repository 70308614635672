@import "../../global-variable";

.admin-news-container {
  @include remove-style-link;

  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08), 0px 2px 4px rgba(0,0,0,0.16);
  margin: 8px;
  max-width: 320px;

  a {
    .admin-news-info {
      display: flex;
      flex-direction: column;
      padding: 10px;

      admin-news-photo{

      }

      .admin-news-date{
        padding: 5px 0;
        font-size: 15px;
      }

      .admin-news-title {
        @include title-s;
        padding: 5px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }

      .admin-news-description {
        @include text-xs;
        color: $dark-more-gray;
        padding: 5px 0
      }

    }
  }
  &:hover {
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0 0 5px 2px rgba(0,0,0,0.4);
  }
}