@import '../../global-variable';

.news-components {
  display: grid;
  padding: 20px;
  background-color: #FFFFFF;
  height: auto;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  @media #{$medium-down} {
    padding: 0;
  }

  @media #{$small-down} {
    padding: 10px;
  }

  .select-block {
    z-index: 50;
    display: flex;
    flex-direction: row;
    justify-content: right;
    flex: 1;

    .css-2613qy-menu {
      z-index: 50;
    }

    .render-type {
      display: flex;
      flex-direction: row;
      padding: 0 14px;

      .four-figures {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 14px;

        .square1 {
          display: block;
          width: 13px;
          height: 13px;
          margin-bottom: 2px;
          background-color: $white;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
        }

        .square2 {
          display: block;
          width: 13px;
          height: 13px;
          background-color: $white;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
        }

        .square3 {
          display: block;
          width: 13px;
          height: 13px;
          background-color: $white;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
        }

        .square4 {
          display: block;
          width: 13px;
          height: 13px;
          margin-right: 2px;
          background-color: $white;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
        }
      }

      .three-figures {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 14px;
        .stripes1 {
          display: block;
          width: 26px;
          height: 6px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
          margin-bottom: 4px;

        }
        .stripes2 {
          display: block;
          width: 26px;
          height: 6px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
          margin-bottom: 4px;
        }
        .stripes3 {
          display: block;
          width: 26px;
          height: 6px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
        }
      }

      .active {
        span {border-color: #037AFF !important;}
      }
    }
  }
  .preview-news-flexbox {
    display: grid;
    grid-template-columns: 1fr;
    @media #{$medium-down} {
      padding-right: 0;
      padding-left: 10px;
    }
    @media #{$small-down} {
      grid-template-columns: 1fr;
    }

    .news-container {
      @include remove-style-link;
      padding: 10px;
      @media #{$small-down} {
        border-bottom: 1px solid #D6D6D6;
        margin-top: 25px;
      }

      a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: 240px;
        @media #{$medium-down} {
          padding: 0;
        }
        @media #{$small-down} {
          align-items: center;
          flex-direction: column;
        }

        .news-logo {
          display: flex;
          justify-content: center;
          max-width: 400px;
          max-height: 240px;
          width: 100%;
          height: 100%;
          @media #{$medium-down} {
            padding: 0;
          }
          @media #{$small-down} {
            width: 200px;
            height: 100px;
          }

          .logo-container {
            display: flex;
            justify-content: center;
            max-width: 400px;
            width: 100%;
            max-height: 240px;
            height: 100%;
            @media #{$medium-down} {
              padding: 0;
            }
            @media #{$small-down} {
              width: 200px;
              height: 100px;
            }
          }
        }
        .news-info {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          padding: 10px 20px;
          @media #{$medium-down} {
            margin-left: 0px;
          }

          date {
            display: flex;
            align-items: center;
            width: 206px;
            line-height: 16px;
            color: #000000;
            text-align: left;
            font-size: 12px;
            margin-top: 30px;

            @media #{$medium-down} {
              margin-top: 10px;
            }
          }
          .title {
            width: 350px;
            color: $black;
            font: bold 24px/28px Roboto;
            text-transform: uppercase;
            padding: 10px 0;
          }
          .description {
            color: $dark-more-gray;
            text-align: left;
            width: 100%;
            height: 100%;
            overflow: hidden;
            font: normal 16px/28px Roboto;
          }
        }
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  .preview-news {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
    @media #{$medium-down} {
      padding-right: 0;
      padding-left: 10px;
    }
    @media #{$small-down} {
      grid-template-columns: 1fr;
    }

    .news-container {
      @include remove-style-link;
      padding: 10px;

      @media #{$small-down} {
        border-bottom: 1px solid #D6D6D6;
      }

      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 394px;
        @media #{$medium-down} {
          padding: 0;
        }
        @media #{$small-down} {
          align-items: center;
        }

        .news-logo {
          display: flex;
          justify-content: center;
          max-width: 640px;
          max-height: 230px;
          width: 100%;
          height: 100%;
          @media #{$medium-down} {
            padding: 0;
          }

          .logo-container {
            display: flex;
            justify-content: center;
            max-width: 640px;
            width: 100%;
            max-height: 230px;
            height: 100%;
            @media #{$medium-down} {
              padding: 0;
            }
          }
        }

        .news-info {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          padding: 0 10px;
          @media #{$medium-down} {
            margin-left: 0px;
          }

          .date {
            display: flex;
            align-items: center;
            width: 206px;
            line-height: 16px;
            color: #000000;
            text-align: left;
            font-size: 12px;
            margin-top: 20px;

            @media #{$medium-down} {
              margin-top: 10px;
            }
          }
          .title {
            width: 350px;
            color: $black;
            font: bold 24px/28px Roboto;
            text-transform: uppercase;
            padding: 10px 0;
          }
          .description {
            color: $dark-more-gray;
            text-align: left;
            width: 100%;
            font: normal 16px/28px Roboto;
          }
        }
      }

      &:hover {
        text-decoration: none;
      }
    }
  }


  a:hover {
    box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.4);
  }
}

.no-news-message:not(:first-child) {
  @media #{$medium-down} {
    margin-top: 24px;
  }
}