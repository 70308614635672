@import "src/scss/global-variable";

.block {
  @include remove-style-link;

  @media #{$small-down} {
    flex-direction: column;
    padding: 10px;
  }

  &:hover {
    text-decoration: none;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0 30px;

  a {
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }

    .content-block {
      display: flex;
      flex-direction: column;
      width: 227px;

      @media #{$medium-down} {
        width: 325px;
      }

      @media #{$small-down} {
        width: 315px;
      }

      .info-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media #{$medium-down} {
          margin: 0 65px;
        }

        .info-fund {
          @include text-s;

          align-items: center;
          display: flex;
          flex-direction: row;
          padding-top: 45px;

          img {
            width: 160px;
            height: 160px;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 50%;
          }
        }

        .title {
          @include title-s;

          padding-bottom: 18px;
          font-size: 20px;
          color: $red;
          text-align: center;
          margin-top: -30px;
        }

        .specialization {
          @include text-xs;

          color: $dark-more-gray;
          //height: 36px;
          padding-bottom: 18px;
          font-size: 14px;
          text-align: center;

        }
      }
    }
  }
}
