@import "../../global-variable";

.program-settings-container {
  width: fit-content;

  label {
    font-weight: bold;
    font-size: 12px;
    line-height: 19px;
    color: #000000;
    opacity: 0.6;
    padding-left: 7px;
  }

  input, textarea, select {
    border: 1px solid #bfbfbf;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 7px 5px;
  }

  .dates-status-container {
    display: flex;
    flex-direction: row;
    width: fit-content;

    .all-dates-container {
      margin-bottom: 7px;
      margin-right: 20px;
      .start-date {
        margin-bottom: 7px;
      }
      .start-date, .exp-date {
        display: flex;
        flex-direction: column;

        input {
          width: 100%;
        }
      }
    }
    .sum-status-container {
      margin-bottom: 7px;
      .target-sum {
        margin-bottom: 7px;
      }
      .target-sum, .status {
        display: flex;
        flex-direction: column;
      }
    }

  }

}