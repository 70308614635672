@import "../global-variable";

.rc-pagination {
  display: flex;
  flex-direction: row;
  height: 30px;
  margin: 0;
  color: $red;

  & li {
    border: none !important;
    background-color: $white;
    outline: transparent none 0px;

    &:hover {
      cursor: pointer;
    }

    & > a {
      color: $red;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }

  .rc-pagination-item {
    border-radius: 0px;
    margin-right: 0;
    color: $red;

    a {
      color: $red;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .rc-pagination-item:hover a {
    color: $red;
  }

  .rc-pagination-prev {
    margin-right: 0;
    border-radius: 15px 0 0 15px;
  }

  .rc-pagination-next {
    border-radius: 0 15px 15px 0;
  }

  .rc-pagination-prev > a, .rc-pagination-next > a {
    color: $red;
    font-size: 26px;
  }

  .rc-pagination-prev a:after, .rc-pagination-next a:after {
    margin-top: -6px;
  }

  .rc-pagination-item-active > a {
    background: $white;
    color: $red;
    text-decoration: underline;
  }

  .rc-pagination-disabled {
    background-color: rgba(0, 0, 0, 0.15);

    & a {
      color: $red;
      opacity: 0.5;
    }
  }

}