@import "components/common/infoBlockFund";
@import "components/common/QRLink";
@import "components/common/documentLinks";
@import "components/common/changeLanguage";
@import "src/scss/components/common/fundContacts";
@import "components/common/popupWidget";
@import "components/common/errorPage";
@import "components/common/notification";
@import "components/common/selectCategories";
@import "components/common/shareStory";
@import "components/common/banner/bannerContainer";
@import "components/common/paginationWidget";
@import "components/common/logo";
@import "components/common/logoCircle";

@import "components/user-footer/footer";
@import "components/user-header/header";
@import "components/user-mainPage/mainPage";
@import "src/scss/components/user-fundPage/fundPage";
@import "src/scss/components/user-projectPage/projectPage";
@import "components/user-searchPage/searchPage";
@import "components/user-donate/donateComponent";
@import "components/user-registrationRequest/registrationRequest";

@import "src/scss/components/logon-widget/logon";
@import "src/scss/components/common/readBillList/readBillList";
@import "src/scss/components/common/calcTotals/calcTotals";

@import "components/admin-container/adminContainer";
@import "components/admin-programListPage/programList.scss";
@import "components/admin-aboutFundPage/aboutFund";
@import "components/admin-editFundPage/editFund";
@import "components/admin-editProgramPage/editProgram";
@import "components/admin-fundsBanner/fundsBanner";
@import "components/admin-editTemplate/editContainer";
@import "components/admin-editTemplate/textInformation";
@import "components/admin-editTemplate/programSettings";
@import "components/admin-feedbackComponent/feedbackForm";
@import "components/admin-newsListPage/newsListPage";
@import "components/admin-newsEditPage/newsEditPage";
@import "components/admin-teamEditPage/teamEditPage";
@import "components/admin-reportsEditPage/reportsEditPage";
@import "components/admin-howGetHelpEditPage/howGetHelpEditPage";
@import "components/admin-projectsPriorityEditPage/projectsPriorityEditPage";

@import "external";

body, html{
  margin:0;
  padding:0;
  width: auto;
}

.user-container {
  background-color: $grey;
  display: flex;
  flex-grow: 1;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.admin-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  width: 100%;
  .admin-page {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    height: 100%;

    .admin-components {
      background-color: $white;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;

      .components-container {
        display: flex;
        flex-direction: column;
        height: 100%

      }
    }
  }
}