@import '../../global-variable';

.program-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 7px;
  padding: 10px 0;
  position: relative;

  @media #{$medium-down} {
    padding-right: 0;
    padding-left: 10px;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
  }
  @media #{$small-down} {
    grid-template-columns: 1fr;
  }
}

.non-project {
  font: normal 16px/28px Roboto;
  padding: 10px;
}