@import "functions";

//global colors

$white: #FFFFFF;
$rgb-white: rgba(255, 255, 255, 0.7);
$grey: #f2f2f2;
$grey-middle: #D6D6D6;
$grey-more-middle: #C4C4C4;;
$dark-grey: #7F7F7F;
$dark-more-gray: #424242;
$black: #000000;
$red: #BE1E24;
$errorColor: #EC1310;
$alert-color: #f04124 !default; // cinnabar
$success-color: #43AC6A !default; // sea-green
$blue: #037AFF;
$warning-color: #f08a24 !default; // carrot
$dark-color: #3b2f31;
$hex: #C52126;

// global variable
$global_width: 1200px;
$br-size-2: 2px;
$br-size-4: 4px;
$font-family-roboto: Roboto;

// global mixins
@mixin box-shadow {
  border: 1px solid $grey-middle;
  box-sizing: border-box;
  box-shadow: 0px 6px 16px rgba(0,0,0,0.1), 0px 3px 7px rgba(0,0,0,0.1);
  border-radius: $br-size-4;
}

@mixin remove-style-link {
  a {
    color: $black;
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }
}

@mixin title-s {
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
}

@mixin title-m {
  font-size: 22px;
  font-weight: bold;
}

@mixin title-l {
  font-size: 24px;
  font-weight: bold;
  line-height: 27px;
}

@mixin text-xs {
  font-size: 12px;
  line-height: 18px;
}

@mixin text-s {
  font-size: 12px;
  line-height: 32px;
}

@mixin text-m {
  font-size: 14px;
  line-height: 18px;
}

@mixin text-l {
  font-size: 16px;
  font-weight: bold;
  line-height: 156%;
}

@mixin btn-active {
  color: $white;
  background-color: $blue;
  box-shadow: 0px 4px 8px rgba(3, 122, 255, 0.16), 0px 4px 4px rgba(0, 0, 0, 0.08);
  border: none;
  font-size: 14px;
  line-height: 32px;
  border-radius: $br-size-4;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.58);
  }
}

@mixin btn-cancel {
  @include box-shadow;
  color: $white;
  background-color: $red;
  box-shadow: 0px 2px 2px rgba(0,0,0,0.06), 0px 2px 4px rgba(0,0,0,0.12);
  border: none;
  font-weight: bold;
  line-height: 36px;
  font-size: 24px;
  width: 100%;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.58);
  }
}

@mixin btn-remove {
  background-color: $white;
  border: 1px solid $blue;
  border-radius: 4px;
  box-sizing: border-box;
  color: $blue;
  height: 40px;
  font: 16px/156% Roboto;
  width: 188px;

  &:hover {
    background-color: $blue;
    color: #fff;
  }
}

// adaptive
$xsmall-breakpoint: em-calc(300) !default;
$small-breakpoint: em-calc(640) !default;
$medium-breakpoint: em-calc(1024) !default;
$large-breakpoint: em-calc(1440) !default;
$xlarge-breakpoint: em-calc(1920) !default;

$xsmall-range: (0, $xsmall-breakpoint) !default;;
$small-range: ($xsmall-breakpoint + em-calc(1), $small-breakpoint) !default;
$medium-range: ($small-breakpoint  + em-calc(1), $medium-breakpoint) !default;
$large-range: ($medium-breakpoint + em-calc(1), $large-breakpoint) !default;
$xlarge-range: ($large-breakpoint  + em-calc(1), $xlarge-breakpoint) !default;
$xxlarge-range: ($xlarge-breakpoint + em-calc(1), em-calc(99999999)) !default;

$screen: "only screen" !default;

$landscape: "#{$screen} and (orientation: landscape)" !default;
$portrait: "#{$screen} and (orientation: portrait)" !default;

$xsmall-up: $screen !default;
$xsmall-only: "#{$screen} and (max-width: #{upper-bound($xsmall-range)})" !default;
$xsmall-down: "#{$screen} and (max-width: #{upper-bound($xsmall-range)})" !default;

$small-up: "#{$screen} and (min-width:#{lower-bound($small-range)})" !default;
$small-only: "#{$screen} and (min-width:#{lower-bound($small-range)}) and (max-width: #{upper-bound($small-range)})" !default;
$small-down: "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;
$medium-down: "#{$screen} and (max-width:#{upper-bound($medium-range)})" !default;

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;
$large-down: "#{$screen} and (max-width:#{upper-bound($large-range)})" !default;

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;
$xlarge-down: "#{$screen} and (max-width:#{upper-bound($xlarge-range)})" !default;

$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-down: "#{$screen}" !default;

$retina: (
        "#{$screen} and (-webkit-min-device-pixel-ratio: 2)",
        "#{$screen} and (min--moz-device-pixel-ratio: 2)",
        "#{$screen} and (-o-min-device-pixel-ratio: 2/1)",
        "#{$screen} and (min-device-pixel-ratio: 2)",
        "#{$screen} and (min-resolution: 192dpi)",
        "#{$screen} and (min-resolution: 2dppx)"
);

$xsmall: $xsmall-up;
$small: $small-up;
$medium: $medium-up;
$large: $large-up;