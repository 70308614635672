@import '../../global-variable';

.requisites-fund {
  padding: 10px 0;

  @media #{$medium-down} {
    width: 94%;
    margin: 0 auto 16px auto;
    font-size: 14px;
    line-height: 20px;
  }

  .requisites-block {
    padding: 10px 0;

    .requisites-fund-title {
      @include title-s;

      @media #{$medium-down} {
        padding: 0 0 10px 0;
      }
    }

    .requisite {
      font: normal 14px/20px Roboto;
    }
  }

}