@import "../../global-variable";
@import "fundSettings";
@import "programSettings";
@import "common";
@import "saveChangesButton";
@import "cancelChangesButton";
@import "confirmAction";
@import "galleryContainer/galleryContainer";

.edit-container {

  .admin-edit-list {
    margin: 16px 0;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(190, 30, 26, 0.2);
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    list-style-type: none;
    padding: 0;

    .default-edit-tab {
      color: #BE1E24;
      opacity: 0.4;
      padding: 6px 16px;
      cursor: pointer;
    }
    .selected-edit-tab {
      color: #FFFFFF;
      background-color: #BE1E24;
      opacity: 1;
      outline: none;
    }
  }

  .recommended-container {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    &:before {
      content: "*";
      color: $red;
      padding-right: 3px;
    }
  }

}