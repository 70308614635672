@import 'src/scss/global-variable';
@import 'bill';

.title-read-bill-list {
  display: flex;
  flex-direction: row;
  .title-supporters {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
  }

  .refresh-supporters {
    background: {
      image: url('../../../../resources/images/autorenew_red.png');
      repeat: no-repeat;
      size: contain;
    };

    height: 28px;
    width: 28px;
    margin-left: 10px;
    &:hover {
      cursor: pointer;
    }
  }
}



.read-bill-list-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 300px;
  overflow: auto;

  @media #{$medium-down} {
    height: 280px;
    padding: 0;
    width: 97%;
  }
}