@import "../../global-variable";

.pagination-container {
  margin-bottom: 30px;
  height: 30px;

  .pagination-pages {
    display: flex;
    justify-content: center;
    font-family: "Roboto", sans-serif;
  }
}