@import "../../global-variable";

.footer {
  background-color: $dark-grey;
  height: 160px;
  width: 100%;

  .title-footer {
    @include text-m;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    color: $white;
    height: 100%;
    width: $global_width;

    .copyright-block {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .logo-service {
        display: flex;
        flex-direction: row;
        align-items: center;

        .logo-mobicash {
          width: 135px;
          height: 25px;
          padding-left: 10px;
          background: {
            image: url('../../../resources/images/logoMobiCash.svg');
            repeat: no-repeat;
            size: cover;
          }
        }

        .logo-db {
          width: 151px;
          height: 47px;
          margin-left: 20px;
          background: {
            image: url('../../../resources/images/logoMobiCashDB_white.svg');
            repeat: no-repeat;
            size: cover;
          }
        }

      }

      p {
        padding-top: 7px;
        margin: 0;
        text-align: center;
      }

      @media #{$medium-down} {
        margin-top: 7px;
      }
    }

    .links-language-block {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media #{$medium-down} {
        margin-bottom: 7px;
      }
    }

    @media #{$medium-down} {
      flex-direction: column;
      width: 100%;
      margin: 0;
    }

  }

  @media #{$medium-down} {
    height: 180px;
  }
}