@import "../../global-variable";

.languages-not-admin {
  @include text-xs;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-end;
  margin-top: 8px;
  width: 100%;

  & > ul {
    box-sizing: border-box;
    display: inline-block;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    color: #fff;
    margin: 0;
    & > li {
      display: inline-block;
      margin: 0;
      padding: 2px;
      opacity: 0.5;

      &:hover, &.is-selected {
        opacity: 1;
      }

      &:not(.is-selected) {
        cursor: pointer;
      }
    }
  }
  @media #{$medium-down} {
    justify-content: center;
  }
}

.languages-admin {
  @include text-l;
  color: $white;
  position: relative;
  margin-right: 16px;
  height: 100%;
  @media (max-width: 600px) {
    width: auto;
    margin-right: 13px;
  }

  img {
    width: 42px;
    height: 42px;
  }
  &:hover{
    cursor: pointer;
  }


  .current-language {
    width: 214px;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    @media (max-width: 600px) {
      width: auto;
    }

    .tick {
      width: 8px;
      height: 8px;
      border-bottom: 1px solid $white;
      border-right: 1px solid $white;
      transform: rotate(45deg);
      margin-left: 8px;
    }
  }

  .wrapper-language {
    display: none;
    position: absolute;
    top: 43px;
    left: 72px;
    padding-top: 25px;

    @media (max-width: 600px) {
      width: auto;
      right: -63px;
      left: auto;
    }

    .fields-language {
      display: flex;
      z-index: 5;
      position: relative;
      flex-direction: column;
      justify-content: space-around;
      box-sizing: border-box;
      padding-top: 16px;
      padding-bottom: 8px;
      width: 214px;
      border-radius: 4px;
      background: #C4C4C4;
      box-shadow: 0 4px 8px rgba($black, 0.08);
      @media (max-width: 600px) {
        width: 190px;
        right: 16px;
      }

      .lang-field {
        display: flex;
        align-items: center;
        height: 40px;
        &:hover {
          cursor: pointer;
          background: rgba($black, 0.08);
        }
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        margin-left: 8px;
      }
      &:before {
        position: absolute;
        top: 0;
        left: 48%;
        display: block;
        width: 20px;
        height: 20px;
        background: #C4C4C4;;
        content: '';
        transform: rotate(45deg) translateX(-50%);
        @media (max-width: 600px) {
          left: 59%
        }
      }
    }
  }
}

.languages-admin:hover .wrapper-language, .fields-language:hover {
  display: block;
  cursor: pointer;
}