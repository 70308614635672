@import "../../global-variable";
@import "programList";
@import "aboutFundComponent";
@import "fundComponentLinks";
@import "sortContainer";
@import "programInfo";
@import "documentsFundComponents";
@import "aboutFundLinks";
@import "team";
@import "requisites";
@import "getHelp";
@import "information";
@import "newsComponentLinks";
@import "newsFundComponents";
@import "oneNewsFundComponents";
@import "mobileMenu";

.fund-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media #{$small-down} {
    margin-top: 80px;
  }

  .headers-fund-container {
    position: relative;
    width: 100%;
    height: 600px;
    @media #{$medium-down} {
      width: 100%;
      height: 100%;
    }

    @media #{$small-down} {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    .full-img {
      position: absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
      width: 100%;
      height: 600px;
      object-fit:cover;

      @media #{$small-down} {
        display: none;
      }
    }

    .mobile-img-container {
      display: none;
      height: 100%;
      width: 100%;

      @media #{$medium-down} {
        display: block;
        width: 100%;
      }

      @media #{$small-down} {
        height: 200px;
      }

      .mobile-img {
        width: 100%;
        height: 100%;
      }
    }

    .fund-container {
      position: absolute;
      bottom: -20px;
      width: 100%;

      @media #{$medium-down} {
        bottom: -155px;
      }

      @media #{$small-down} {
        position: static;
        height: 100%;
      }
    }

    .return-to-fund-about {
      position: absolute;
      top: 15%;
      left: 17%;
      @media #{$medium-only} {
        top: 7%;
      }
      @media #{$medium-down} {
        left: 3%;
      }

      .return-flex-container {
        margin-top: 30px;
        height: 30px;
        background: $white;
        color: $red;
        border-radius: 14px;
        border: none;
        display: flex;
        flex-direction: row;

        @include remove-style-link;

        a {
          display: flex;
          flex-direction: row;
          padding: 5px 10px;

          img {
            width: 10px;
            height: 20px;
          }

          .link-return {
            height: 100%;
            width: 100%;
            color: $red;
            font: bold 12px Roboto;
            padding-left: 5px
          }
        }

        &:hover {
          text-decoration: none;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.58);
        }
      }
    }
  }

  .mobile-title-page {
    visibility: hidden;
    height: 0;

    @media #{$small-down} {
      visibility: visible;
      color: #0E2771;
      font: bold 14px/150% Roboto;
      text-transform: uppercase;
      height: 100%;
      padding: 10px 10px;
    }
  }

  .common-container {
    margin: 0 auto 50px auto;
    width: $global_width;
    z-index: 2;
    @media #{$medium-down} {
      width: 100%;
    }
  }
}