@import "src/scss/global-variable";
@import "progressBar";

.calc-totals-container {
  font-size: 14px;
  line-height: 18px;

  .full-dress {
    display: flex;
    flex-direction: column;

    .title-full-dress {
      display: flex;
      flex-direction: row;

      .title-status {
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
      }

      .refresh-supporters {
        background: {
          image: url('../../../../resources/images/autorenew_red.png');
          repeat: no-repeat;
          size: contain;
        };
        height: 28px;
        width: 28px;
        margin-left: 10px;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .total-support-container, .days-goal-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .total, .support, .days-left, .goal{
        display: flex;
        flex-direction: column;

        .total-value, .support-value {
          font-weight: bold;
          font-size: 24px;
          line-height: 36px;
        }

        .support-value, .goal {
          text-align: right;
        }
      }
    }
  }

  .not-full-dress {
    display: flex;
    flex-direction: column;
    margin: auto;

    .total-support-percent-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px 0;

      @media #{$medium-down} {
        align-items: center;
      }

      .total-support {
        display: flex;
        flex-direction: column;

        .total {
          display: flex;
          flex-direction: row;
        }

        .total-value {
          font-weight: bold;
          color: $red;
          padding-right: 5px;
        }

        .support {
          p {
            margin: 0;
          }
        }
      }

      .percent {
        font-weight: bold;
        font-size: 24px;
        line-height: 18px;
        text-align: right;
      }
    }


  }
}