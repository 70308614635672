@import "../../global-variable";

.priority-edit-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 25px;

  input {
    display: none;
  }

  .table-container {
    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        padding-bottom: 10px;
      }

      tbody {
        border: 1px solid $grey-more-middle;
      }

      td:first-child {
        width: 30px;

        .dragging {
          background: {
            image: url('../../../resources/images/dragging.png');
            size: cover;
            repeat: no-repeat;
          };
          height: 20px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      td {
        border-bottom: 1px solid $grey-more-middle;
        padding: 10px;

        .project-logo {
          width: 150px;
          height: 110px;
          box-shadow: 0 0 7px #666;
        }
      }

      td {
        .project-title {
        }
      }
    }
  }
}