@import "../../global-variable";

.block-container {
  @include box-shadow;

  display: flex;
  flex-direction: column;
  background-color: $white;
  margin: -60px auto 50px auto;
  min-height: 434px;
  width: $global_width;
  max-width: 1200px;
  padding-bottom: 45px;

  @media #{$medium-down} {
    width: 96%;
  }

  .title-sort-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 40px;
    padding: 15px 15px 5px 15px;

    @media #{$small-down} {
      flex-direction: column-reverse;
      height: 100%;
    }

    .title {
      @include title-m;
    }
  }

  .slick-slider {
    padding: 29px;

    .slick-list {
      margin: -7px;

      @media #{$medium-only} {
        margin: 46px;
      }
    }

    .slick-track {
      transform: none;

      @media #{$medium-down} {
        .block-fund {
          width: 300px !important;
        }
      }

      @media #{$small-down} {
        .block-fund {
          width: 333px !important;
        }
      }
    }
  }

  .slick-prev:before, .slick-next:before {
    color: $red;
    font-size: 35px;
  }

  .slick-prev {
    left: 17px;
    z-index: 5;
  }

  .slick-next {
    right: 35px;
    z-index: 5;
  }
}


