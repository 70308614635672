@import '../../global-variable';

.about-fund-links {
  @include remove-style-link;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  font: normal 14px/150% Roboto;
  padding: 20px 10px;
  border-bottom: 1px solid #D6D6D6;
  &:hover {
    text-decoration: none;
  }

  @media #{$small-down} {
    display: none;
    padding: 0;
    height: 0;
    width: 0;
  }

  .about-link {
    padding-right: 20px;

    .about-link-active {
      font-weight: bold;
      color: #E73224;
    }
  } &:hover {
      cursor: pointer;
    }
}