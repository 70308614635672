@import '../../global-variable';

.tagline {
  display: flex;
  flex-direction: column;
  //margin: -60px auto 30px auto;

  .tagline-first {
    font-weight: bold;
    line-height: normal;
    font-size: 24px;
    text-align: center;
    margin: 0;
    padding-top: 45px;

    @media #{$large-up} {
      font-size: 36px;
      text-align: right;
      padding: 0;
    }
  }

  .tagline-second {
    width: inherit;
    font-weight: normal;
    margin: 7px 30px;
    line-height: 21px;
    font-size: 14px;
    text-align: center;
    padding-left: 0;

    @media #{$large-up} {
      white-space: pre-line;
      line-height: 27px;
      font-size: 18px;
      text-align: right;
      padding-left: 400px;
      margin: 0;
    }
  }
}