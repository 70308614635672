@import "src/scss/global-variable";

.progress-bar-container {
  padding: 10px 0 5px 0;

  .progress-bar {
    width: 100%;
    height: 14px;
    display: flex;
    flex-direction: row;

    .collected {
      border-radius: 6px 0 0 6px;
    }

    .default {
      border-radius: 0 6px 6px 0;
    }
  }
}