@import '../../global-variable';

.admin-menu {
  background: linear-gradient(0deg, rgba(3, 122, 255, 0.02), rgba(3, 122, 255, 0.02)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #FFFFFF;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 256px;
  z-index: 5;

  .links-account-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85%;

    .panel-menu {
      padding-left: 0;
      padding-top: 15px;

      a {
        color: $black;
        font-size: 16px;
        list-style-type: none;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }

        .link {
          padding: 8px 10px;
          &:hover {
            background-color: $hex;
            cursor: pointer;
          }

          &:active {
            border-left: 4px solid $hex;
          }
        }

        .link-active {
          background: linear-gradient(0deg, rgba(3, 122, 255, 0.02), rgba(3, 122, 255, 0.02)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #FFFFFF;
          border-left: 4px solid $hex;
          font-weight: bold;
          &:hover {
            background-color: $hex;
          }
        }
      }
    }
  }

  .lang-document-container {

    p {
      color: white;
      font-size: 12px;
      text-align: center;
      margin: 15px;
    }
  }
  .languages {
    justify-content: center;
    margin-bottom: 10px;

    & > ul {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

}