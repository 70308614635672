@import "../../global-variable";

.documents-component-container {
  background-color: #FFFFFF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 15px;

  .documents-container {
    display: flex;
    flex-direction: column;

    .title {
      @include title-m;
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 10px;

      .item {
        font: normal 16px/28px Roboto;
      }
    }
  }
}