@import "../../global-variable";

.checkbox-form {

  input[type='checkbox'] {
    display: none;
  }

  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.checkbox-form label {
  display: inline-block;
  padding-left: 22px;
  position: relative;
}

.checkbox-form label::before,
.checkbox-form label::after {
  content: "";
  display: inline-block;
  position: absolute;
}

.checkbox-form label::before {
  border: 2px solid $dark-grey;
  border-radius: 4px;
  box-sizing: border-box;
  height: 18px;
  left: 0;
  top: 3px;
  width: 18px;
}

.checkbox-form label::after {
  background: {
    image: url(../../../resources/images/check.png);
    size: cover;
    repeat: no-repeat;
  }
;
  height: 18px;
  left: 2px;
  width: 18px;
}

.checkbox-form input[type='checkbox'] + label::after {
  content: none;
}

.checkbox-form input[type='checkbox']:checked + label::after {
  content: "";
}