@import "components";
@import "customize";
@import "fonts";
@import "global-variable";
@import "helper";

body {
  font-family: Roboto, sans-serif;
  margin: 0;
  padding: 0;

  //@media #{$medium-down} {
  //  display: flex;
  //  position: fixed;
  //  top: 0;
  //  left: 0;
  //  right: 0;
  //}

  .app-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    max-width: 100vw;
    background-color: #F5F5F5;

    .app-content {
      flex-grow: 1;
    }
  }

  .is-hidden {
    @include not-displayed;
  }

  .no-link {
    pointer-events: none;

    div {
      opacity: 0.4;
    }
  }
}

