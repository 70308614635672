@import "../../global-variable";
@import "searchResultBlock";

.search-container {
  display: flex;
  flex-direction: column;

  .search-field {
    display: flex;
    flex-direction: row;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.1);
    margin: -30px auto 30px auto;
    height: 64px;
    width: $global_width;
    font-size: 16px;
    line-height: 24px;

    @media #{$medium-down} {
      width: 96%;
    }
    input {
      border: 1px solid $grey-middle;
      box-sizing: border-box;
      border-radius: 4px;
      opacity: 0.4;
      width: 100%;
      padding: 8px;
      margin: 12px 0 12px 12px;
      cursor: text;
    }
    button {
      width: 168px;
      margin: 12px;
      background-color: $red;
      border: none;
      border-radius: 4px;
      color: $white;

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.58);
      }
    }
  }

  .search-result-field {
    display: block;
    width: $global_width;
    color: $black;
    margin: 0 auto 30px auto;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1), 0 3px 7px rgba(0, 0, 0, 0.1);

    @media #{$medium-down} {
      width: 96%;
    }

    .nothing-found {
      padding: 12px 12px 8px 12px;
    }

  }
}