.css-2b097c-container {

  .css-yk16xz-control {
    .css-g1d714-ValueContainer {
      max-height: 60px;
      overflow: auto !important;
    }
  }

  .css-1pahdxg-control {
    .css-g1d714-ValueContainer {
      max-height: 60px;
      overflow: auto !important;
    }
  }


}