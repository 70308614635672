@import "../../global-variable";

.info-fund-block {
  align-items: center;
  background-color: $white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  height: 210px;
  padding-top: 6px;
  justify-content: space-between;

  @media #{$medium-down} {
    border-radius: 0;
    height: 100%;
  }
  @media #{$small-down} {
    flex-direction: column;
    height: 100%;
  }

  .logo-text-fund {
    display: flex;
    flex-direction: row;
    .logo-fund {
      align-self: flex-start;
      border-radius: 50%;
      border: 0.5px solid #EBEBEB;
      box-sizing: border-box;
      height: 120px;
      min-width: 120px;
      margin: 10px 16px;
      img {
        border-radius: 50%;
        height: 100%;
      }
      @media #{$medium-down} {
        height: 80px;
        min-width: 80px;
      }
    }
    .info-fund {
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      padding: 10px 10px 5px 0;
      width: 100%;

      .title {
        @include title-l;
        padding-bottom: 10px;

        @media #{$medium-down} {
          line-height: 18px;
          font-size: 14px;
        }

      }
      .story {
        @include text-xs;
        color: $dark-more-gray;
        font-size: 14px;
        line-height: 160%;

        p {
          margin: 0;
        }

        a {
          color: $red;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }


  .donate-fund {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    justify-content: center;
    @media #{$medium-only} {
      margin-right: 36px;
    }
    @media #{$small-down} {
      width: 94%;
      margin-right: 0;
      margin-bottom: 24px;
    }
    .button-donate-container {
      @media #{$small-down} {
        width: 100%;
      }
      .btn-donate {
        @media #{$small-down} {
          width: 100% !important;
          height: 48px;
        }
        width: 130px !important;
        &:hover {
          cursor: pointer;
        }
      }
    }

  }
}

.mode-admin {
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
}

.mode-user {
  margin: -1px auto 20px auto;
  max-width: $global_width;

  @media #{$medium-down} {
    width: 100%;
    margin: 0 0 25px 0;
  }

}