@import "../../global-variable";

.help-edit-container {
  height: 100%;
  padding: 10px 25px;

  .description-container {
    display: flex;
    flex-direction: column;

    label {
      font-weight: bold;
      font-size: 12px;
      line-height: 19px;
      color: #000000;
      opacity: 0.6;
      padding-left: 7px;
      padding-bottom: 10px;

      &:after {
        content: "*";
        color: $red;
        opacity: 1;
        padding-left: 3px;
      }
    }
    input, textarea {
      border: 1px solid #bfbfbf;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 7px 5px;
    }
    textarea {
      min-height: 160px;
    }
  }
}