@import "../../global-variable";

.confirm-popup-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 400px;

  .confirm-warning-message {
    @include text-m;
    color: $dark-more-gray;
    text-align: center;
    padding-top: 12px;
  }

  .confirm-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;

    .yes-button {
      @include btn-active;
      width: 100px;
      margin: 0 10px;
    }

    .no-button {
      @include btn-remove;
      width: 100px;
      margin: 0 10px;
    }
  }
}