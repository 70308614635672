@import "../../global-variable";

.save-button {
  @include btn-active;
  color: $white;
  font-size: 16px;
  height: 40px;
  line-height: 100%;
  margin: 0 10px 0 0;
  width: 188px;

  &:hover {
    cursor: pointer;
  }
}

.save-button[disabled] {
  background-color: #cccccc;
  border: 2px solid $dark-grey;
  box-shadow: none;

  &:hover {
    background-color: #cccccc;
    cursor: default;
  }
}